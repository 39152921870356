import Vue from 'vue'
import App from './App.vue'
import VueTypedJs from 'vue-typed-js'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import './assets/styles/index.css';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

Vue.config.productionTip = false

delete Icon.Default.prototype._getIconUrl;

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
Vue.use(VueTypedJs)
Vue.use(VueMeta)
Vue.use(VueI18n)
import router from './router';

const messages = {
  "fr":{
    "home":{
      "security":"Sécurité et disponibilité",
      "why":"Pourquoi nous choisir",
      "security_about":"Basé sur une infrastructure de pointe, nos services sont hautement disponibles et incluent toutes les dernieres innovations vous permettant de faire fonctionner vos services du mieux possible.",
      "support":"Support 7j/7",
      "support_about":"Notre équipe de support technique est disponible pour vous accompagner tous les jours de la semaine, même les weekends.",
      "ddos":"Protection anti-DDoS",
      "ddos_about":"L’ensemble de nos services est protégé contre les attaques DDoS grâce à la technologie Arbor.",
      "infra":"Infrastructure de pointe",
      "infra_about":"Nous utilisons du matériel récent pour vous fournir la meilleur qualité de service, quels que soient vos besoins.",
      "backup":"Backup",
      "backup_about":"Bénéficiez de sauvegardes quotidiennes de vos données afin de vous prémunir d’éventuelles pertes ou défaillances.",
      "open_source":"Open Source",
      "open_source_about":"L’open source est au cœur de nos services, avec des partenaires de confiance. Vous pouvez consulter la liste des produits que nous utilisons.",
      "evolution":"Une evolution continue",
      "evolution_about":"Afin de vous fournir la meilleur qualité de services nous travaillons chaque jour pour améliorer nos offres et en créer de nouvelles.",
      "domain_name":"Nom de domaine",
      "domain_name_headline":"Commencez par un nom de domaine !",
      "domain_name_about":"Démarrez votre présence sur internet par nom de domaine",
      "order":"Commander",
      "payment":"Paiement",
      "payment_headline":"Nos moyens de paiement",
      "payment_about":"Nous acceptons la majorité des solutions de paiement y compris SEPA !",
      "purchase":"Commander",
      "month":"mois",
      "unlimited":"Illimités"
    },
    "vps":{
      "headline":"Découvrez nos offres VPS",
      "headline2":"L'offre VPS est conçue pour les personnes ayant besoin d'un serveur sous Linux pour une utilisation standard avec de bonnes performances",
      "name":"Nom",
      "cpu":"CPU",
      "ram":"Ram",
      "storage":"Stockage",
      "bandwith":"Bande Passante",
      "price":"Tarif Mensuel",
      "os":"Nos OS disponibles en installation automatique",
      "presetos":"Déployez en quelques secondes nos images pré-conçues",
      "otheros":"Nos OS disponibles en installation manuelle",
      "oslist":"Installez l'OS de votre choix depuis notre catalogue d'ISO",
      "kvm":"Propulsée par la technologie KVM",
      "kvm_headline":"Notre offre VPS est propulsée par la technologie KVM, cette solution permet de bénéficier de nombreuses fonctions tel que Docker, Fuse ou encore modifier votre kernel à la volée.",
      "snapshot":"Snapshot sécurisée",
      "snapshot_desc":"Nous avons mis en place une solution de snapshots pour votre serveur VPS directement depuis votre panel, vous pouvez ainsi créer et restaurer vous même des snapshots de votre VPS.",
      "arbor":"Anti-DDoS Arbor",
      "arbor_desc":"La protection contre les attaques par déni de service est incluse dans toutes nos offres. Lors de la détection d'une attaque, celle-ci est mitigée en quelques secondes de manière automatique."
    },
    "web":{
      "website":"Site web",
      "mail_account":"Comptes e-mails",
      "mail_account_desc":"Nos offres d'hébergement vous permettent de bénéficier d'un hébergement email simple IMAP/POP/SMTP avec la configuration automatique de la signature DKIM",
      "database":"Bases de Donnée",
      "database_desc":"Avec votre offre Web vous bénéficiez de deux types de bases de données : MySQL et PostegreSQL",
      "popular":"La plus populaire",
      "headline":"Découvrez nos offres Web",
      "headline2":"Que ce soit pour votre site web personnel ou professionnel, comparez nos offres et sélectionnez celle qui vous convient le mieux",
      "offer1_desc":"Notre offre basique pour lancer vos projets de petite taille",
      "offer2_desc":"Destiné aux projets de taille moyenne, cette offre vous assure un service hautement disponible",
      "offer3_desc":"Destiné aux projets de grandes taille cette offre repousse toutes les limites",
      "domain":"Nom de domaine",
      "domain_desc":"Commencez par un nom de domaine !",
      "domain_headline":"Démarrez votre présence sur internet par nom de domaine",
      "vesta":"Pour concevoir notre offre d'hébergement nous nous sommes appuyés sur le panel de gestion VestaCP dans sa dernière version, réputé dans le domaine de l'hébergement web ce panel vous permet de gérer en quelques clics tous les paramètres essentiels de votre hébergement web.",
      "php":"Multiversions de PHP",
      "php_desc":"Nos offres d'hébergement web supportent les versions PHP 7.2, 7.4 et 8.0, vous pouvez en un clic changer votre version de PHP depuis le panel.",
      "ssl":"SSL Let's Encrypt",
      "ssl_desc":"Votre hébergement bénéficie gratuitement de certificats SSL édités par l'autorité Let's Encrypt, vous pouvez simplement sécuriser votre site internet depuis votre panel."
    },
    "vpn":{
      "headline":"Découvrez notre offre VPN",
      "headline2":"Avec plus de 30 pays et un chiffrement fort, notre VPN vous accompagne dans toutes les tâches de votre quotidien et garantit votre anonymat sur Internet.",
      "subscription":"Abonnement",
      "years":"ans",
      "year":"an",
      "per_months":"par mois",
      "save":"Economisez",
      "choose_subscription":"Choisir l'abonnement",
      "ht":"Nos tarifs sont exprimés hors-taxes",
      "anonymous":"Aucune trace",
      "anonymous_desc":"Nous ne conservons aucune donnée de vos connexions pour garantir votre anonymat.",
      "everywhere":"Partout où vous allez",
      "everywhere_desc":"Nous fournissons un client VPN pour Windows, Mac OS et Android afin de vous faciliter l’utilisation au quotidien, partout où vous allez. Vous pouvez connecter jusqu’à 5 appareils simultanément par compte.",
      "worldwide":"Portée mondiale",
      "worldwide_desc":"Plus de 30 localisations disponibles partout dans le monde",
      "secure":"Sécurité accrue",
      "secure_desc":"Nous supportons plusieurs protocoles, comme OpenVPN, IKEv2 et L2TP, avec un chiffrement fort de bout-en-bout.",
      "fast":"Rapide",
      "fast_desc":"Nos serveurs ont accès à des connexions de 1 Gbps au minimum, vous permettant de profiter pleinement de votre débit.",
      "evolution":"Une evolution continue",
      "evolution_desc":"Afin de vous fournir la meilleur qualité de services nous travaillons chaque jour pour améliorer nos offres et en créer de nouvelles."
    },
    "ssl":{
      "headline":"Découvrez nos offres SSL",
      "headline2":"Sécurisez vos transactions avec nos certificats SSL et profitez du HTTPS.",
      "offer1_desc":"Parfait pour les sites personnels",
      "offer2_desc":"Parfait pour vos sites e-commerce",
      "offer3_desc":"Pour sécuriser tout vos sous domaine.",
      "encryption":"Chiffrement 256-bit",
      "encryption_desc":"La clé de chiffrement de nos certificats est disponible au niveau de sécurité maximum accepté par les navigateurs actuels.",
      "domainvalidation":"Validation du Domaine",
      "domain_and_subdomain":"Domaine et ∞ sous-domaine",
      "seal":"Sceau SSL",
      "seal_desc":"Les sceaux de site sécurisé permettent d'indiquer aux visiteurs d'un site web que l'entreprise a investi dans l'une des technologies de sécurité les plus utilisées et qu'elle s'engage à les protéger contre les attaques de phishing et la surveillance de leurs données.",
      "browsers":"Compatible avec 99% des navigateurs",
      "browsers_desc":"Nos certificats sont reconnus par plus de 99% des navigateurs du marché (IE/Edge, Firefox, Chrome, etc.)"
    },
    "mails":{
      "headline":"Découvrez nos offres Mails",
      "headline2":"Bénéficiez de boites mails professionelles pour votre entreprise",
      "offer1_desc":"Parfait pour les sites personnels",
      "of_storage":"de stockage",
      "offer2_desc":"Parfait pour les petites équipes",
      "offer3_desc":"Pour les besoins importants",
      "domains":"Domaines",
      "oneclick":"Gestion en un clic",
      "oneclick_desc":"Notre offre Mail est flexible et sans engagement : vous pouvez ajouter et supprimer des boites Mail d'un seul clic.",
      "simple":"Simple d'accès",
      "simple_desc":"Notre webmail est accessible depuis n'importe quel ordinateur, smartphone ou tablette. Bien-sûr, vous pouvez également choisir d'utiliser vos applications Mail préférées si vous le souhaitez.",
      "virus":"Antivirus / Antispam",
      "virus_desc":"Nos offres incluent un Antivirus / Antispam déjà actif sur votre boîte mail pour vous protéger et protéger votre travail des menaces de sécurité, en agissant sur les messages indésirables avant même que le courrier ne soit téléchargé.",
      "disclaimer":"Sans limite * : Tant que notre infrastructure n'est pas mise en danger"
    },
    "jobs":{
      "headline":"Nos offres d'emploi",
      "headline2":"Interessé par un poste chez HydroChain ?",
      "headline3":"Retrouvez sur cette page toutes nos offres d'emploi"
    },
    "domains":{
      "headline":"Réservez votre nom de domaine aujourd'hui",
      "headline2":"Une idée, un projet, un nom de domaine",
      "offer":"Commandez votre nom de domaine",
      "offer_desc":"Démarrez votre présence sur internet par un nom de domaine",
      "transfert":"Achat et transfert de nom de domaine",
      "transfert_desc":"Confiez-nous la gestion de votre nom de domaine. Achetez votre nom de domaine directement sur notre site ou transférez-le grâce au code AUTH que votre prestataire actuel vous a communiqué.",
      "identity":"Protégez votre identité",
      "identity_desc":"Lors de la souscription, choisissez de nous confier le mandat de propriété ou choisissez de masquer vos informations tout en restant propriétaire de votre nom de domaine. Faites une demande pour l’Anonymiseur de nom de domaine en ouvrant une demande d’assistance technique.",
      "ext":"Extensions",
      "register":"Enregistrement",
      "trans":"Transfert",
      "renewal":"Renouvellement"
    },
    "gaming":{
      "headline":"Découvrez nos offres Jeux",
      "headline2":"Débutant ou expert, gérer un serveur n'a jamais été aussi facile. Avec le panel, personnalisez le serveur, installez mods, plugins et addons, changez de map, console live, etc.",
      "wait":"Nous sommes en train de preparer la sortie de cette offre",
      "wait_desc":"Inscrivez-vous a notre newsletter pour etre tenu informé de leur parution"
    },
    "about":{
      "title":"A Propos",
      "headline":"Nous sommes Hydrosaas",
      "headline2":"Notre equipe est composé de profils trés differents les un des autres avec chacun leur specialité ainsi vous êtes toujours entre de bonnes mains",
      "feature1":"Une offre créée pour s’adapter à vos besoins",
      "feature2":"HydroSaaS vous offre des solutions d’hébergement adaptées à vos besoins : Infrastructures cloud, serveurs virtuels, hébergement de sites web ou même VPN pour votre entreprise.",
      "feature3":"L’équipe jeune, motivée et compétente d’HydroSaaS est là pour vous assister tout au long du processus et pour répondre à toutes vos attentes en terme d’infrastructure Cloud.",
      "tech_title":"Nos Technologies",
      "tech_headline":"Voici les technologies que nous utilisons",
      "tech_headline2":"HydroSaaS vous procure des outils à la pointe de la technologie, tout en s’assurant de la stabilité et de la sécurité des services proposés.",
      "follow":"Suivez-nous !"
    },
    "nav":{
      "web":"Web",
      "hosting":"Hebergement Web",
      "gaming":"Gaming",
      "domain":"Nom de Domaine",
      "vps":"VPS",
      "vpn":"VPN",
      "mail":"Mails",
      "client":"Espace Client"
    },
    "hero":{
      "partner":"Votre partenaire spécialisé en",
      "more":"En savoir plus",
      "headline":"Hydrosaas s’engage à vous fournir des solutions à haute disponibilité. Un service de qualité pour donner un coup de boost à vos projets."
    },
    "footer":{
      "about":"À propos",
      "mentions":"Mentions légales",
      "ht":"Les prix affichés sont Hors Taxes",
      "tos":"CGU"
    }
  },
  "en": {
    "home": {
      "security": "Security and availability",
      "why": "Why choose us",
      "security_about": "Based on a state of the art infrastructure, our services are highly available and include all the latest innovations that allow you to run your services in the best possible way.",
      "support": "7/7 support",
      "support_about": "Our technical support team is available to accompany you every day of the week, even on weekends.",
      "ddos": "Anti-DDoS protection",
      "ddos_about": "All of our services are protected against DDoS attacks using Arbor technology.",
      "infra": "State-of-the-art infrastructure",
      "infra_about": "We use the latest equipment to provide you with the best quality of service, whatever your needs.",
      "backup": "Backup",
      "backup_about": "Benefit from daily backups of your data to protect you from possible losses or failures.",
      "open_source": "Open Source",
      "open_source_about": "Open source is at the heart of our services, with trusted partners. You can consult the list of products we use below.",
      "evolution": "A continuous evolution",
      "evolution_about": "In order to provide you with the best quality of services we work every day to improve our offers and create new ones.",
      "domain_name": "Domain name",
      "domain_name_headline": "Start with a domain name!",
      "domain_name_about": "Start your internet presence by domain name",
      "order": "Order",
      "payment": "Payment",
      "payment_headline": "Our means of payment",
      "payment_about": "We accept the majority of payment solutions including SEPA!",
      "purchase": "Order",
      "month": "month",
      "unlimited": "Unlimited"
    },
    "vps": {
      "headline": "Discover our VPS offers",
      "headline2": "The VPS offer is designed for people who need a Linux-based server for standard use with good performance.",
      "name": "Name",
      "cpu": "CPU",
      "ram": "Ram",
      "storage": "Storage",
      "bandwith": "Bandwidth",
      "price": "Monthly Rate",
      "os": "Our OSes available in automatic installation",
      "presetos": "Deploy our pre-designed images in seconds",
      "otheros": "Our OSes available for manual installation",
      "oslist": "Install the OS of your choice from our ISO catalog",
      "kvm": "Powered by KVM technology",
      "kvm_headline": "Our VPS offer is powered by KVM technology, this solution allows you to benefit from many features such as Docker, Fuse or even modify your kernel on the fly.",
      "snapshot": "Secure Snapshot",
      "snapshot_desc": "We have set up a snapshots solution for your VPS server directly from your panel, so you can create and restore snapshots of your VPS yourself.",
      "arbor": "Anti-DDoS Arbor",
      "arbor_desc": "Protection against denial of service attacks is included in all our offers. When an attack is detected, it is mitigated automatically within seconds."
    },
    "web": {
      "website": "Website",
      "mail_account": "E-mail accounts",
      "mail_account_desc": "Our hosting offers allow you to benefit from a simple IMAP/POP/SMTP email hosting with automatic DKIM signature configuration.",
      "database": "Data Bases",
      "database_desc": "With your Web offer you benefit from two types of databases: MySQL and PostegreSQL",
      "popular": "The most popular",
      "headline": "Discover our Web offers",
      "headline2": "Whether for your personal or professional website, compare our offers and select the one that suits you best.",
      "offer1_desc": "Our basic offer to launch your small projects",
      "offer2_desc": "Intended for medium-size projects, this offer ensures you a highly available service",
      "offer3_desc": "Intended for large projects, this offer pushes all the limits.",
      "domain": "Domain Name",
      "domain_desc": "Start with a domain name!",
      "domain_headline": "Start your internet presence by domain name",
      "vesta": "To design our hosting offer we have relied on the VestaCP management panel in its latest version, renowned in the field of web hosting, this panel allows you to manage in a few clicks all the essential parameters of your web hosting.",
      "php": "Multi-versions of PHP",
      "php_desc": "Our web hosting offers support PHP versions 7.2, 7.4 and 8.0, you can change your PHP version in one click from the panel.",
      "ssl": "SSL Let's Encrypt",
      "ssl_desc": "Your hosting benefits from free SSL certificates issued by the Let's Encrypt authority, you can simply secure your website from your panel."
    },
    "vpn": {
      "headline": "Discover our VPN offer",
      "headline2": "With more than 30 countries and strong encryption, our VPN accompanies you in all your daily tasks and guarantees your anonymity on the Internet.",
      "subscription": "Subscription",
      "years": "years",
      "year": "an",
      "per_months": "per month",
      "save": "Save",
      "choose_subscription": "Choose the subscription",
      "ht": "Our rates are expressed without taxes",
      "anonymous": "No trace",
      "anonymous_desc": "We do not keep any data of your connections to guarantee your anonymity.",
      "everywhere": "Wherever you go",
      "everywhere_desc": "We provide a VPN client for Windows, Mac OS and Android to make it easier for you to use it every day, wherever you go. You can connect up to 5 devices simultaneously per account.",
      "worldwide": "Global Reach",
      "worldwide_desc": "More than 30 locations available worldwide",
      "secure": "Enhanced security",
      "secure_desc": "We support several protocols, such as OpenVPN, IKEv2 and L2TP, with strong end-to-end encryption.",
      "fast": "Fast",
      "fast_desc": "Our servers have access to connections of at least 1 Gbps, allowing you to take full advantage of your throughput.",
      "evolution": "A continuous evolution",
      "evolution_desc": "In order to provide you with the best quality of services we work every day to improve our offers and create new ones."
    },
    "ssl": {
      "headline": "Discover our SSL offers",
      "headline2": "Secure your transactions with our SSL certificates and take advantage of HTTPS.",
      "offer1_desc": "Perfect for personal sites",
      "offer2_desc": "Perfect for your e-commerce sites",
      "offer3_desc": "To secure all your sub-domains.",
      "encryption": "256-bit encryption",
      "encryption_desc": "The encryption key for our certificates is available at the maximum security level accepted by current browsers.",
      "domainvalidation": "Domain Validation",
      "domain_and_subdomain": "Domain and ∞ sub-domain",
      "seal": "SSL Seal",
      "seal_desc": "Secure Site Seals let website visitors know that the company has invested in one of the most widely used security technologies and is committed to protecting them from phishing attacks and monitoring their data.",
      "browsers": "Compatible with 99% of browsers",
      "browsers_desc": "Our certificates are recognized by more than 99% of browsers on the market (IE/Edge, Firefox, Chrome, etc.)."
    },
    "mails": {
      "headline": "Discover our Mails offers",
      "headline2": "Benefit from professional mailboxes for your company",
      "offer1_desc": "Perfect for personal sites",
      "of_storage": "storage",
      "offer2_desc": "Perfect for small teams",
      "offer3_desc": "For important needs",
      "domains": "Domains",
      "oneclick": "One-click management",
      "oneclick_desc": "Our Mail offer is flexible and without obligation: you can add and remove mailboxes with a single click.",
      "simple": "Easy access",
      "simple_desc": "Our webmail is accessible from any computer, smartphone or tablet. Of course, you can also choose to use your favorite mail applications if you wish.",
      "virus": "Antivirus / Antispam",
      "virus_desc": "Our offers include an Antivirus / Antispam already active on your mailbox to protect you and your work from security threats, acting on unwanted messages before the mail is even downloaded.",
      "disclaimer": "No limit * : As long as our infrastructure is not endangered"
    },
    "jobs": {
      "headline": "Our job offers",
      "headline2": "Interested in a position at HydroChain?",
      "headline3": "Find on this page all our job offers"
    },
    "domains": {
      "headline": "Reserve your domain name today",
      "headline2": "An idea, a project, a domain name",
      "offer": "Order your domain name",
      "offer_desc": "Start your internet presence with a domain name",
      "transfert": "Domain name purchase and transfer",
      "transfert_desc": "Entrust us with the management of your domain name. Buy your domain name directly on our site or transfer it using the AUTH code that your current provider has given you.",
      "identity": "Protect your identity",
      "identity_desc": "At the time of subscription, choose to entrust us with the ownership mandate or choose to hide your information while remaining the owner of your domain name. Apply for Domain Name Anonymizer by opening a technical support request.",
      "ext": "Extensions",
      "register": "Registration",
      "trans": "Transfer",
      "renewal": "Renewal"
    },
    "gaming": {
      "headline": "Discover our games offers",
      "headline2": "Beginner or expert, managing a server has never been easier. With the panel, customize the server, install mods, plugins and addons, change maps, live console, etc...",
      "wait": "We are currently preparing the release of this offer.",
      "wait_desc": "Subscribe to our newsletter to be kept informed of their publication."
    },
    "about": {
      "title": "About us",
      "headline": "We are Hydrosaas",
      "headline2": "Our team is composed of very different profiles each with their own speciality so you are always in good hands.",
      "feature1": "An offer created to adapt to your needs",
      "feature2": "HydroSaaS offers you hosting solutions adapted to your needs: Cloud infrastructures, virtual servers, website hosting or even VPN for your company.",
      "feature3": "HydroSaaS' young, motivated and competent team is there to assist you throughout the process and to meet all your expectations in terms of Cloud infrastructure.",
      "tech_title": "Our Technologies",
      "tech_headline": "Here are the technologies we use",
      "tech_headline2": "HydroSaaS provides you with state-of-the-art tools, while ensuring the stability and security of the services offered.",
      "follow": "Follow us!"
    },
    "nav": {
      "web": "Web",
      "hosting": "Web Hosting",
      "gaming": "Gaming",
      "domain": "Domain name",
      "vps": "VPS",
      "vpn": "VPN",
      "mail": "Mails",
      "client": "Customer Area"
    },
    "hero": {
      "partner": "Your partner specialized in",
      "more": "To know more about it",
      "headline": "Hydrosaas is committed to providing you with high availability solutions. A quality service to give a boost to your projects."
    },
    "footer": {
      "about": "About Hydrosaas",
      "mentions": "Legal Notice",
      "ht": "The prices shown are exclusive of tax",
      "tos": "ToS"
    }
  }
};

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages
});


new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
