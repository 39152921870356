import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'
Vue.use(Router);
Vue.use(VueMeta);

const routes = [
	// =============================================================================
	// FULL PAGE LAYOUTS
	// =============================================================================
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/Home.vue')
	},
	{
		path: '/vpn',
		name: 'vpn',
		component: () => import('@/views/VPN.vue')
	},
	{
		path: '/vps',
		name: 'vps',
		component: () => import('@/views/VPS.vue')
	},
	{
		path: '/web',
		name: 'web',
		component: () => import('@/views/Web.vue')
	},
	{
		path: '/mails',
		name: 'mails',
		component: () => import('@/views/Mails.vue')
	},
	{
		path: '/mentions',
		name: 'mentions',
		meta: {
			title: 'Mentions Légales HydroSaas',
			metaTags: [
				{
					name: 'description',
					content: 'Apprenez en un peu plus sur nous'
				},
				{
					property: 'og:description',
					content: 'Apprenez en un peu plus sur nous'
				}
			]
		},
		component: () => import('@/views/Mentions.vue')
	},
	{
		path: '/tos',
		name: 'tos',
		component: () => import('@/views/ToS.vue')
	},
	{
		path: '/gaming',
		name: 'gaming',
		component: () => import('@/views/Gaming.vue')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/views/About.vue')
	},
	{
		path: '/tld',
		name: 'domain',
		component: () => import('@/views/Domain.vue')
	},
	// Redirect to 404 page, if no match found
	{
		path: '*',
		redirect: '/'
	}
];


const router = new Router({
	routes,
	mode: 'history'
});



export default router
